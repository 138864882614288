$periwinkle-color: #F6F1FF;
$wisteria-color: #e6e1f4;
$wonder-color: #baa9e9;
$moonlight-color: #fff4e3;
$soft-sage-color: #e8f2e8;
$ink-color: #2a2448;
$amythyst-color: #856ecb;
$wildberry-color: #623BA5;
$galaxy-color: #3e0a5c;
$heather-color: #9b85de;
