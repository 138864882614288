@import "variables-colors";

html,
body {
  padding: 0;
  margin: 0;
  font-family: Archivo, "Source Sans Pro", sans-serif;
  color: $ink-color;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

.highlighted-light-green-grid-row {
  background-color: rgb(211, 231, 197) !important;
}

.highlighted-light-yellow-grid-row {
  background-color: rgb(255, 255, 204) !important;
}

.highlighted-dark-green-grid-row {
  background-color: rgb(88, 191, 111) !important;
}

.ant-menu-submenu-selected > .ant-menu-submenu-title {
  color: #623ba5 !important;
}

.ant-btn-default:not(:disabled):hover {
  color: #623ba5 !important;
  border-color: #623ba5;
}

.ant-menu-submenu-title {
  height: 100% !important;
  display: flex !important;
  align-items: center !important;
}

.ant-menu-submenu-selected::after {
  border-bottom-color: rgba(98, 59, 165, 0.7) !important;
  border-bottom-width: 3px !important;
}

.ant-menu-overflow-item {
  display: flex !important;
  align-items: center !important;
}

.ant-menu-submenu-active::after,
.ant-menu-item-active::after {
  border-bottom-color: rgba(98, 59, 165, 0.5) !important;
  border-bottom-width: 3px !important;
}

.ant-menu-submenu-open::after {
  border-bottom-color: rgba(98, 59, 165, 0.5) !important;
  border-bottom-width: 3px !important;
}

:not(.ant-menu-overflow-item).ant-menu-item-selected {
  color: black !important;
  background-color: rgba(230, 225, 244, 1) !important;
}
.ant-menu-overflow-item.ant-menu-item-selected {
  color: #623ba5 !important;
}

.ant-menu-overflow-item.ant-menu-item-selected:after {
  border-bottom-color: rgba(98, 59, 165, 0.7) !important;
  border-bottom-width: 3px !important;
}

.ant-menu-item-only-child:hover {
  background-color: rgba(230, 225, 244, 1) !important;
}

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  color: #623ba5 !important;
  border-color: #623ba5 !important;
}

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before {
  background-color: #623ba5 !important;
}

.ant-radio-button-wrapper:hover {
  color: rgba(98, 59, 165, 0.5) !important;
}

.ant-divider-horizontal.ant-divider-with-text-left::before,
.ant-divider-horizontal.ant-divider-with-text-left::after {
  //border-top: 1px solid rgba(98, 59, 165, 0.7);
  border-top: 2px solid $amythyst-color;
}

.ant-divider-horizontal.ant-divider-with-text {
  margin: 20px 0 !important;
}

.ant-btn-primary:not(:disabled) {
  background-color: rgba(98, 59, 165, 0.7) !important;
}

.ant-btn-primary:not(:disabled):hover {
  background-color: rgba(98, 59, 165, 0.5) !important;
}

.ag-header-viewport {
  background-color: rgba($wonder-color, 0.7);
}
